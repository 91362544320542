<template>
    <a-table
            class="ant-table-striped"
            size="middle"
            :columns="columns"
            :data-source="liveInfo"
            :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
            bordered
            :pagination="paginationObject"
    />
</template>
<script>
    const columns = [
        /*{
            title: '学号',
            dataIndex: 'studNo',
            align:'center'
        },
        {
            title: '姓名',
            dataIndex: 'studName',
            align:'center'
        },*/
        {
            title: '直播名称',
            dataIndex: 'liveName',
            align:'center'
        },
        {
            title: '直播时间',
            dataIndex: 'liveTime',
            align:'center',
            width:180,
            customRender:(informName, row, index) => {
                return row.liveStartTime + " ~ " +row.liveEndTime;
            }
        },
        {
            title: '观看时间',
            dataIndex: 'viewTime',
            align:'center'
        },
        {
            title: '观看时长/视频时长',
            dataIndex: 'durationTime',
            align:'center',
            customRender:(informName, row, index) => {
                return row.joinTimeStr +" / "+row.durationTime
            }

        },
        {
            title: '主讲人',
            dataIndex: 'anchorTeacherName',
            align:'center'
        },
        {
            title: '观看类型',
            dataIndex: 'joinType',
            align:'center',
            customRender:(informName, row, index) => {
                return row.joinType ==1?"直播":"回放"
            }
        },
    ];
    export default {
        name: 'live_history',
        data(){
            return {
                columns,
                paginationObject: {
                    current: 1,
                    total: 0,
                    pageSize: 1,
                    onChange: this.onPageChange,
                },
            }
        },
        mounted() {
            this.initPaginationObject()
        },
        methods:{
            onPageChange(page, pageSize) {
                this.paginationObject.current = page
                this.liveSearchParams.current = page
                this.liveSearchParams.size = this.paginationObject.pageSize
                this.$emit("queryList")
            },
            initPaginationObject(){
                this.paginationObject.pageSize = this.liveSearchParams.pageSize
                this.paginationObject.total = this.liveSearchParams.total
                this.paginationObject.current = this.liveSearchParams.current
            }
        },
        watch: {
            liveSearchParams:{
                handler(nv, ov) {
                    this.paginationObject.total = nv.total
                },
                immediate: false,
                deep:true
            }
        },
        props:{
            liveInfo: {
                type: Array
            },
            liveSearchParams: {
                type: Object
            }
        }
    }
</script>
<style scoped>
    .ant-table-striped :deep(.table-striped) td {
        background-color: #fafafa;
    }
</style>
