<!--
 * @Author: xiaziwen
 * @Date: 2021-01-08 14:02:18
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-10 14:13:25
 * @FilePath: \crm-education\src\views\student\my_study\c\LiveTab.vue
-->
<template>
  <a-card :bordered="false" class="cus-card my-study-live-tab no-border">
    <template slot="title">
      <div class="d-flex justify-between align-center">
        <p class="fs-14">{{ currentTermName }}</p>
        <a-select
          v-model="liveSearchParams.termId"
          style="width: 120px"
          @change="onSelectChange"
          placeholder="请选择学期"
        >
          <a-select-option v-for="term in termList" :value="term.termId" :key="term.termId">
            {{ term.termName }}
          </a-select-option>
        </a-select>
      </div>
    </template>
    <a-tabs v-model="active" class="cus-tabs" @change="onTabChange">
      <a-tab-pane key="1">
        <template slot="tab">
          <p class="fs-18">近期直播</p>
        </template>
        <a-row :gutter="34">
          <a-col class="mb-10" :md="{ span: 8 }" :xs="{ span: 24 }" v-for="(item, i) in liveInfo" :key="i">
            <panel-live :options="item"></panel-live>
          </a-col>
        </a-row>
        <a-empty v-if="!liveInfo.length" />
      </a-tab-pane>
      <a-tab-pane key="2">
        <template slot="tab">
          <p class="fs-18">直播记录</p>
        </template>
        <div class="py-15">
          <a-form class="cus-form" layout="inline" :form="form">
            <a-row type="flex" align="middle">
              <a-col :md="{ span: 6 }" :xs="{ span: 24 }">
                <a-form-item label="直播名称">
                  <a-input v-model="liveSearchParams.liveName" placeholder="请输入直播名称"> </a-input>
                </a-form-item>
              </a-col>
              <a-col :md="{ span: 6 }" :xs="{ span: 24 }">
                <a-form-item label="主教老师">
                  <a-input v-model="liveSearchParams.anchorTeacherName" placeholder="请输入主教老师"> </a-input>
                </a-form-item>
              </a-col>
              <a-col :md="{ span: 6 }" :xs="{ span: 24 }">
                <a-form-item label="查看类别" has-feedback>
                  <a-select class="cus-select" v-model="liveSearchParams.liveType" placeholder="选择类别" @change="resData">
                    <a-select-option :value="item.code" v-for="(item, index) in livingType" :key="index">
                      {{ item.desc }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="{ span: 6 }" :xs="{ span: 24 }">
                <a-form-item>
                  <a-button type="primary" html-type="submit" class="cus-button" @click="onQueryList">
                    查询
                  </a-button>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <a-row :gutter="34" v-show="liveSearchParams.liveType !=3">
          <a-col class="mb-10" :md="{ span: 8 }" :xs="{ span: 24 }" v-for="(item, i) in liveInfo" :key="i">
            <panel-live :options="item" ></panel-live>
          </a-col>
        </a-row>
        <live-history @queryList="onQueryList()" :liveSearchParams = liveSearchParams :liveInfo="viewRecord" v-show="liveSearchParams.liveType ==3"></live-history>
        <a-empty v-if="!liveInfo.length" v-show="liveSearchParams.liveType !=3"/>
      </a-tab-pane>
      <a-tab-pane key="4">
        <template slot="tab">
          <p class="fs-18">全部直播</p>
        </template>
        <template v-if="!showDetail">
          <div class="py-15">
            <a-form class="cus-form" layout="inline" :form="form">
              <a-row type="flex" align="middle">
                <a-col :md="{ span: 6 }" :xs="{ span: 24 }">
                  <a-form-item label="直播名称">
                    <a-input v-model="liveSearchParams.specialName" placeholder="请输入直播名称"> </a-input>
                  </a-form-item>
                </a-col>
                <a-col :md="{ span: 6 }" :xs="{ span: 24 }">
                  <a-form-item label="主教老师">
                    <a-input v-model="liveSearchParams.anchorTeacherName" placeholder="请输入主教老师"> </a-input>
                  </a-form-item>
                </a-col>
                <a-col :md="{ span: 6 }" :xs="{ span: 24 }">
                  <a-form-item>
                    <a-button type="primary" html-type="submit" class="cus-button" @click="onQueryList">
                      查询
                    </a-button>
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form>
          </div>
          <a-row :gutter="34">
            <a-col class="mb-10" :md="{ span: 8 }" :xs="{ span: 24 }" v-for="(item, i) in liveInfo" :key="i">
              <panel-live2 :options="item" @detail="getLivingDetail(item)"></panel-live2>
            </a-col>
          </a-row>
          <a-empty v-if="!liveInfo.length" />
        </template>
        <template v-else>
          <a-button class="mb-20" type="primary" @click="backList"><a-icon type="left" />返回</a-button>
          <a-row :gutter="34">
            <a-col class="mb-10" :md="{ span: 8 }" :xs="{ span: 24 }" v-for="(item, i) in detailLivingList" :key="i">
              <panel-live :options="item"></panel-live>
            </a-col>
          </a-row>
          <a-empty v-if="!detailLivingList.length" />
        </template>
      </a-tab-pane>
      <a-tab-pane key="3">
        <template slot="tab">
          <p class="fs-18">直播课表</p>
        </template>
        <div class="py-5">
          <a-form class="cus-form" layout="inline" :form="form">
            <a-row type="flex" align="middle">
              <a-col :md="{ span: 6 }" :xs="{ span: 24 }">
                <a-form-item label="直播名称">
                  <a-input v-model="liveSearchParams.liveName" placeholder="请输入直播名称"> </a-input>
                </a-form-item>
              </a-col>
              <a-col :md="{ span: 6 }" :xs="{ span: 24 }">
                <a-form-item label="主教老师">
                  <a-input v-model="liveSearchParams.anchorTeacherName" placeholder="请输入主教老师"> </a-input>
                </a-form-item>
              </a-col>
              <!-- <a-col :md="{ span: 6 }" :xs="{ span: 24 }">
                <a-form-item label="查看类别" has-feedback>
                  <a-select class="cus-select" v-model="liveSearchParams.liveType" placeholder="选择类别">
                    <a-select-option :value="item.code" v-for="(item, index) in livingType" :key="index">
                      {{ item.desc }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col> -->
              <a-col :md="{ span: 6 }" :xs="{ span: 24 }">
                <a-form-item>
                  <a-button type="primary" html-type="submit" class="cus-button" @click="onQueryList">
                    查询
                  </a-button>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <live-calendar
          ref="liveCalendar"
          :content="liveInfo"
          :live-dates="liveDates"
          @select="calendarSelect"
          @switch="handleSwitch"
        ></live-calendar>
      </a-tab-pane>
    </a-tabs>
  </a-card>
</template>

<script>
import moment from 'moment'
import PanelLive from '@/components/business/panel/Live'
import LiveHistory from '@/components/business/panel/LiveHistory'
import PanelLive2 from '@/components/business/panel/Live2'
import { livingType } from '@/config/dict'
import LiveCalendar from './LiveCalendar'
import api from '@/api'

export default {
  name: 'my_study_live_tab',
  components: {
    PanelLive,
    LiveCalendar,
    PanelLive2,
    LiveHistory
  },
  props: {
    activeKey: {
      type: String,
      default: '1'
    },
    liveSearchParams: {
      type: Object
    },
    termList: {
      type: Array
    },
    liveInfo: {
      type: Array
    },
    viewRecord: {
      type: Array
    }
  },
  data() {
    return {
      livingType,
      showDetail: false,
      detailLivingList: [],
      liveDates: [],
      form: null
    }
  },
  computed: {
    active: {
      get() {
        return this.activeKey || '1'
      },
      set(val) {
        if (val === '3') {
          const date = moment(new Date())
          this.getMonthAllLive(date.format('yyyy-MM'))
          this.$emit('update:liveSearchParams', { ...this.liveSearchParams, liveTime: date.format('yyyy-MM-DD') })
        }
        this.$emit('update:activeKey', val)
      }
    },
    currentTermName() {
      let term = this.termList.find(term => term.termId === this.liveSearchParams.termId)
      if (term) {
        return term.termName
      }
      return ''
    }
  },
  methods: {
    onTabChange() {
      this.resetData()
    },
    onSelectChange() {
      this.resetData()
    },
    resetData() {
      this.liveSearchParams.anchorTeacherName = ''
      this.liveSearchParams.liveName = ''
      this.liveSearchParams.liveType = ''
      this.liveSearchParams.liveTime = ''
      this.liveSearchParams.total = 0
      this.liveSearchParams.pageSize = 10
      this.liveSearchParams.current = 1
      this.$emit('on-refresh')
    },
    resData() {
      this.liveSearchParams.anchorTeacherName = ''
      this.liveSearchParams.liveName = ''
      this.liveSearchParams.liveTime = ''
      this.liveSearchParams.total = 0
      this.liveSearchParams.pageSize = 10
      this.liveSearchParams.current = 1
      this.$emit('on-refresh')
    },
    onPanelLiveClick() {},
    onQueryList() {
      this.$emit('on-refresh')
    },
    calendarSelect({ currentDate }) {
      const date = currentDate.format('yyyy-MM-DD')
      this.$emit('update:liveSearchParams', { ...this.liveSearchParams, liveTime: date })
      this.onQueryList()
    },
    async getLivingDetail({ specialId }) {
      const { data = [] } = await api.student.getAllLiveDetail({ specialId })
      this.detailLivingList = data
      this.toggleDetail()
    },
    toggleDetail() {
      this.showDetail = !this.showDetail
    },
    backList() {
      this.toggleDetail()
      this.detailLivingList = []
    },
    // 获取某月所有直播安排
    async getMonthAllLive(currentDate) {
      const res = await api.student.getCurrentMonthLiveMsg({ ...this.liveSearchParams, liveTime: currentDate })
      if (res.code === 200) {
        this.liveDates = res.data.map(ele => ele.liveStartTime)
      }
    },
    handleSwitch(date) {
      this.calendarSelect({ currentDate: date })
      this.getMonthAllLive(date.format('YYYY-MM'))
    }
  }
}
</script>

<style lang="scss" scoped>
.my-study-live-tab {
  .cus-select {
    width: 140px;
  }
}
</style>
