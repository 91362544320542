<!--
 * @Author: xiaziwen
 * @Date: 2021-01-07 11:51:13
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-02-03 17:05:47
 * @FilePath: \crm-education\src\components\business\panel\Live.vue
-->
<template>
  <a-card class="panel-card" hoverable style="width: 240px">
    <div class="live-panel" slot="cover">
      <div
        class="detail d-flex col align-center justify-center"
        :style="{ backgroundImage: 'url(' + (options.specialImg ? options.specialImg : '') + ')' }"
      >
        <div v-show="isShow">
          <div class="title fs-18 mb-15 fw-bold">{{ options.specialName }}</div>
          <div class="sub-author fs-14 fw-bold">主讲教师：{{ options.anchorTeacherName || '暂无' }}</div>
        </div>
      </div>
      <div class="meta fs-12" v-show="statusTxt">即将开始（{{ options.afterTime }}小时之后）</div>
      <div class="hover-mask d-flex align-center justify-center">
        <a-button type="primary" @click="onButtonClick">查看详情</a-button>
      </div>
    </div>
    <a-card-meta class="text-left">
      <template slot="title">
        <div class="live-title fs-16 fw-bold">{{ options.specialName }}</div>
      </template>
      <template slot="description">
        <slot :options="options">
          <div class="author fs-14">主讲：{{ options.anchorTeacherName || '暂无' }}</div>
          <div class="date fs-14">时间：{{ options.nearTime }}</div>
          <div class="level fs-14">
            已播节数：{{ options.alreadyCount || 0 }}/直播节数：{{ options.liveCount || 0 }}
          </div>
        </slot>
      </template>
    </a-card-meta>
  </a-card>
</template>

<script>
import './Live.scss'
export default {
  name: 'panel_live',
  data() {
    return {}
  },
  props: {
    options: {
      required: false,
      type: Object,
      default: () => ({})
    }
  },
  computed: {
     isShow(){
       if(this.options.specialImg){
         return false
       }
       return true
    },
    statusTxt() {
      const { liveState = '', countdown = '' } = this.options
      let text = ''
      if (liveState === '1' && Number(countdown) <= 0) {
        text = `直播进行中`
      } else if ((liveState === '2' || liveState === '1') && Number(countdown) > 0) {
        text = `即将开始（${countdown}小时之后）`
      } else if (liveState === '3') {
        text = '看回放'
      } else if (liveState === '4') {
        text = '未上课'
      }
      return text
    }
  },
  methods: {
    onButtonClick() {
      this.$emit('detail', this.options)
    }
  }
}
</script>
