<!--
 * @Author: xiaziwen
 * @Date: 2021-01-08 10:24:59
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-10 14:11:07
 * @FilePath: \crm-education\src\views\student\my_study\index.vue
-->
<template>
  <div class="student-my-study">
    <div class="mb-10">
      <a-card :bordered="false" class="cus-card no-border">
        <a-tabs v-model="activeKey" class="cus-tabs">
          <a-tab-pane key="1">
            <template slot="tab">
              <p class="fs-18">课程学习</p>
            </template>
            <a-row :gutter="34">
              <a-col :md="{ span: 8 }" :xs="{ span: 24 }">
                <progress-bar
                  :options="{
                    theme: '#4071fb',
                    size: '64px',
                    title: '课程',
                    url: 'process-icon-kc',
                    denom: gradeInfo.courseCount || 0, // 分母
                    num: gradeInfo.passCount || 0 // 分子
                  }"
                ></progress-bar>
              </a-col>
              <a-col :md="{ span: 8 }" :xs="{ span: 24 }">
                <!--<progress-bar
                  :options="{
                    theme: '#fc9038',
                    title: '学分',
                    url: 'process-icon-xf',
                    denom: gradeInfo.courseCredit || 0,
                    num: gradeInfo.obtainCredit || 0,
                    size: '64px'
                  }"
                ></progress-bar>-->
              </a-col>
              <a-col :md="{ span: 8 }" :xs="{ span: 24 }">
                <progress-bar
                  :options="{
                    theme: '#ad3afc',
                    title: '最低毕业学分',
                    url: 'process-icon-zdxf',
                    minNum: parseInt(gradeInfo.lowestCredit) || 0,
                    size: '64px'
                  }"
                ></progress-bar>
              </a-col>
            </a-row>
          </a-tab-pane>
          <a-tab-pane key="2">
            <template slot="tab">
              <p class="fs-18">直播课堂</p>
            </template>
            <p class="text-center fs-20">
              <span v-if="getLivingTitle">{{ getLivingTitle }}季</span>直播安排
            </p>
            <div class="live-tags d-flex justify-between align-center py-15 text-center">
              <div class="tag-wrap linear-gradient-blue">
                <div class="tag">
                  <p class="fs-18 fw-bold">{{ livePlanInfo.allLiveCount || 0 }}</p>
                  <p class="fs-14">总直播数</p>
                </div>
              </div>
              <div class="tag-wrap linear-gradient-light-orange">
                <div class="tag">
                  <p class="fs-18 fw-bold">{{ livePlanInfo.overLiveCount || 0 }}</p>
                  <p class="fs-14">已结束直播数</p>
                </div>
              </div>
              <div class="tag-wrap linear-gradient-purple">
                <div class="tag">
                  <p class="fs-18 fw-bold">
                    {{ livePlanInfo.recentLiveCount || 0 }}
                  </p>
                  <p class="fs-14">近期直播</p>
                </div>
              </div>
              <div class="tag-wrap linear-gradient-dark-orange">
                <div class="tag">
                  <p class="fs-18 fw-bold">{{ livePlanInfo.joinLiveCount || 0 }}</p>
                  <p class="fs-14">参与直播次数</p>
                </div>
              </div>
              <div class="tag-wrap linear-gradient-light-blue">
                <div class="tag">
                  <p class="fs-18 fw-bold">{{ livePlanInfo.watchLiveCount || 0 }}</p>
                  <p class="fs-14">观看直播次数</p>
                </div>
              </div>
            </div>
          </a-tab-pane>
          <!-- <a-tab-pane key="3">
            <template slot="tab">
              <p class="fs-18">微课讲堂</p>
            </template>
            <div class="d-flex align-center">
              <p class="fs-14 mr-10">课程名称</p>
              <a-input class="search-input mr-10"></a-input>
              <a-button type="primary" class="cus-button">查询</a-button>
            </div>
            <div class="section-header dec d-flex align-center justify-between flex-wrap py-20">
              <h2 class="base-title fs-18">推荐课程</h2>
              <p class="fs-14 color-gray link"></p>
            </div>
            <a-row :gutter="30">
              <a-col class="mb-10" :md="{ span: 8 }" :xs="{ span: 24 }" v-for="i in 6" :key="i">
                <panel-course @onbtnclick="onPanelLiveClick"></panel-course>
              </a-col>
            </a-row>
          </a-tab-pane> -->
        </a-tabs>
      </a-card>
    </div>
    <div class="mb-10">
      <template v-if="activeKey === '1'">
        <my-course
          :activeKey.sync="termActiveKey"
          :termList.sync="termList"
          :courseInfo="courseInfo"
          @refresh="initCourse"
        ></my-course>
      </template>
      <template v-else-if="activeKey === '2'">
        <live-tab
          :activeKey.sync="liveActiveKey"
          :liveSearchParams.sync="liveSearchParams"
          :termList="termList"
          :liveInfo="liveInfo"
          :viewRecord="viewRecord"
          @on-refresh="onRefreshLive()"
        ></live-tab>
      </template>
      <!-- <template v-else-if="activeKey === '3'">
        <a-card :bordered="false" class="cus-card no-border">
          <template slot="title">
            <div class="section-header dec d-flex align-center justify-between flex-wrap">
              <h2 class="base-title fs-18">全部课程</h2>
              <p class="fs-14 color-gray link"></p>
            </div>
          </template>

          <a-row :gutter="30">
            <a-col class="mb-10" :md="{ span: 8 }" :xs="{ span: 24 }" v-for="i in 6" :key="i">
              <panel-course @onbtnclick="onPanelLiveClick"></panel-course>
            </a-col>
          </a-row>
        </a-card>
      </template> -->
    </div>
  </div>
</template>

<script>
// import helper from '@/utils/helper'
import ProgressBar from '@/components/business/progressbar'
// import PanelCourse from '@/components/business/panel/Course'
import MyCourse from '../home/c/MyCourse'
import LiveTab from './c/LiveTab'
import api from '@/api'

export default {
  name: 'student_my_study',
  components: {
    ProgressBar,
    MyCourse,
    // PanelCourse,
    LiveTab
  },
  data() {
    return {
      // studId: helper.getUserInfo('userId'),
      activeKey: '1',
      gradeInfo: {},
      courseInfo: {},
      termList: [],
      termActiveKey: '',
      livePlanInfo: {},
      liveInfo: [],
      liveActiveKey: '1',
      viewRecord:[],
      liveSearchParams: {
        anchorTeacherName: '',
        specialName: '',
        liveName: '',
        liveType: '',
        liveTime: '',
        termId: '',
        pageSize:10,
        current:1,
        total:0
      }
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    activeKey: {
      handler(nv, ov) {
        if (nv === '1') {
          this.initCourseAbout()
        } else if (nv === '2') {
          this.initLiveAbout()
        }
      },
      immediate: true
    }
  },
  computed: {
    getLivingTitle() {
      // 匹配当前学期标题
      const matchTerm = this.termList.find(ele => ele.termId === this.liveSearchParams.termId)
      return matchTerm && matchTerm.termName
    }
  },
  methods: {
    async init() {
      this.initQuery()
      await this.initCourseAbout()
    },
    initQuery() {
      let { query } = this.$route
      this.activeKey = query.activeKey ? query.activeKey : this.activeKey
      this.termActiveKey = query.termActiveKey ? query.termActiveKey : this.termActiveKey
      this.liveActiveKey = query.liveActiveKey ? query.liveActiveKey : this.liveActiveKey
    },
    async initCourseAbout() {
      await this.initGrade()
      await this.initTermDicts()
      await this.initCourse()
    },
    async initLiveAbout() {
      this.initTermDicts()
      await this.initLivePlan()
      await this.initRecentlyLive()
    },
    onRefreshLive() {
      this.initLivePlan()
      this.initRecentlyLive()
    },
    async initGrade() {
      let res = await api.student.getGrade()
      if (res.code === 200) {
        this.gradeInfo = res.data
      }
    },
    async initCourse() {
      const data = {
        termId: this.termActiveKey
      }
      let res = await api.student.getCourse(data)
      if (res.code === 200) {
        this.courseInfo = res.data
      }
    },
    async initTermDicts() {
      let termRes = await api.student.getTerm()
      if (termRes.code === 200 && termRes.data.length > 0) {
        this.termList = termRes.data
        this.termActiveKey = termRes.data[0].termId
        let temp = termRes.data.filter(item => item.currentTeaching === 'Y')
        if(temp!=null && temp.length>0){
          this.termActiveKey = temp[0].termId
        }
        this.liveSearchParams.termId = this.termActiveKey
      }
    },
    async initLivePlan() {
      const data = {
        termId: this.liveSearchParams.termId
      }
      let res = await api.student.getLivePlan(data)
      if (res.code === 200) {
        this.livePlanInfo = res.data
      }
    },
    async initRecentlyLive() {
      let res = {}
      if (this.liveActiveKey === '4') {
        // tab3全部直播
        const data = {
          ...this.liveSearchParams,
          selectType: this.liveActiveKey
        }
        res = await api.student.getAllLive(data)
      } else {
        const data = {
          ...this.liveSearchParams,
          selectType: this.liveActiveKey
        }

        if(this.liveActiveKey == 2&& this.liveSearchParams.liveType == 3){
          res = await api.student.getLiveViewRecord(data)
          this.liveSearchParams.total = res.total
          if(res.code === 200){
            this.viewRecord = res.data || []
          }
          return
        }else {
          res = await api.student.getRecentlyLive(data)
        }
      }
      if (res.code === 200) {
        this.liveInfo = res.data || []
      }
    },
    onPanelLiveClick(options) {
      console.log(options)
    }
  }
}
</script>

<style lang="scss" scoped>
.student-my-study {
  .search-input {
    width: 200px;
  }
  .live-tags {
    .tag-wrap {
      width: 19%;
      min-width: 100px;
      border-radius: 3px;
      overflow: hidden;
      .tag {
        padding: 20px 0;
        color: #fff;
        background-size: 54px 54px;
        background-position: left bottom;
        background-repeat: no-repeat;
        &:nth-child(1) {
          background-image: url('../../../assets/img/study-tag-1.png');
        }
      }
    }
  }
}
</style>
