<!--
 * @Author: xiaziwen
 * @Date: 2021-01-08 15:27:09
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-09 10:30:14
 * @FilePath: \crm-education\src\views\student\my_study\c\LiveCalendar.vue
-->
<template>
  <a-row :gutter="34">
    <a-col :md="{ span: 18 }" :xs="{ span: 24 }">
      <a-calendar
        class="cus-calendar"
        v-model="currentDate"
        :fullscreen="false"
        :header-render="headerRender"
        @select="handleSelect"
      >
        <template slot="dateFullCellRender" slot-scope="value">
          <div class="cus-date-cell">
            <div class="date-value-wrap" :class="getClasses(value)">
              <div class="date-value">{{ value.date() }}</div>
            </div>
          </div>
        </template>
      </a-calendar>
    </a-col>
    <a-col :md="{ span: 6 }" :xs="{ span: 24 }">
      <div class="calendar-detail">
        <p class="title fs-16 mb-10">{{ currentDate.format('yyyy-MM-DD') }}</p>
        <div class="live-list pl-20" v-if="content.length">
          <div class="live-info-item" v-for="(item, index) in content" :key="index">
            <p class="live-info-item-title fw-bold fs-16">{{ item.liveName || '暂无专题' }}</p>
            <p class="fs-14 color-gray">主讲：{{ item.anchorTeacherName || '暂无主讲' }}</p>
            <p class="fs-14 color-gray">
              时间：<span v-if="item.liveStartTime">{{ item.liveStartTime }}~{{ item.liveStartTime }}</span
              ><span v-else>暂无时间</span>
            </p>
          </div>
        </div>
        <a-empty v-else />
      </div>
    </a-col>
  </a-row>
</template>

<script>
import moment from 'moment'
export default {
  name: 'my_study_live_calendar',
  props: { content: { type: Array, default: () => [] }, liveDates: { type: Array, default: () => [] } },
  data() {
    return {
      currentDate: moment(new Date())
    }
  },
  methods: {
    headerRender({ value, type, onChange, onTypeChange }) {
      const year = value.year()
      const month = value.month()
      const preMonth = () => {
        let newMonth = value.clone().month(month)
        if (month <= 0) {
          const _year = year - 1
          const newYear = value.clone().year(_year)
          const _month = 11
          newMonth = newYear.month(_month)
          // onChange(newYear)
          onChange(newMonth)
        } else {
          const _month = month - 1
          newMonth = value.clone().month(_month)
          onChange(newMonth)
        }
        this.$emit('switch', newMonth)
      }
      const nextMonth = () => {
        let newMonth = value.clone().month(month)
        if (month >= 11) {
          const _year = year + 1
          const newYear = value.clone().year(_year)
          const _month = 0
          newMonth = newYear.month(_month)
          // onChange(newYear)
          onChange(newMonth)
        } else {
          const _month = month + 1
          newMonth = value.clone().month(_month)
          onChange(newMonth)
        }
        this.$emit('switch', newMonth)
      }
      return (
        <div class="cus-calendar-header py-10 d-flex align-end">
          <div class="date-wrap">
            <p class="fs-18 pb-5">{year}</p>
            <div class="cus-month-picker">
              <span class="link pre-month-btn" onClick={preMonth}></span>
              <div class="month-select fs-36 fw-bold">{month + 1}月</div>
              <span class="link next-month-btn" onClick={nextMonth}></span>
            </div>
          </div>
          <div class="instructions">
            <div class="live">直播</div>
          </div>
        </div>
      )
    },
    getClasses(value) {
      let classes = []
      if (value.month() === moment(new Date()).month()) {
        classes.push('current-month')
      }
      if (value.isSame(moment(new Date()), 'day')) {
        classes.push('today')
      }
      if (value.isSame(this.currentDate, 'day')) {
        classes.push('current-day')
        classes = classes.filter(c => c !== 'today')
      }
      if (this.liveDates.includes(value.format('yyyy-MM-DD'))) {
        classes.push('has-live')
      }

      return classes.join(' ')
    },
    handleSelect() {
      this.$emit('select', { currentDate: this.currentDate })
    }
  }
}
</script>

<style lang="scss" scoped>
.cus-calendar {
  .cus-calendar-header {
    .date-wrap {
      width: 140px;
      text-align: center;
    }
    .cus-month-picker {
      position: relative;
      height: 44px;
      .pre-month-btn {
        left: 10px;
        height: 100%;
        position: absolute;
        top: 0;
        display: inline-block;
        padding: 0 5px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 16px;
        font-family: Arial, Hiragino Sans GB, Microsoft Yahei, 'Microsoft Sans Serif', sans-serif;
        line-height: 40px;
        &::before {
          position: relative;
          top: 0;
          display: inline-block;
          vertical-align: middle;
          width: 0;
          height: 0;
          border-top: 5px solid transparent;
          border-right: 10px solid rgba(0, 0, 0, 0.45);
          border-bottom: 5px solid transparent;
          content: '';
        }
      }
      .next-month-btn {
        right: 10px;
        height: 100%;
        position: absolute;
        top: 0;
        display: inline-block;
        padding: 0 5px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 16px;
        font-family: Arial, Hiragino Sans GB, Microsoft Yahei, 'Microsoft Sans Serif', sans-serif;
        line-height: 40px;
        &::before {
          position: relative;
          top: 0;
          display: inline-block;
          vertical-align: middle;
          width: 0;
          height: 0;
          border-top: 5px solid transparent;
          border-left: 10px solid rgba(0, 0, 0, 0.45);
          border-bottom: 5px solid transparent;
          content: '';
        }
      }
      .month-select {
        line-height: 38px;
      }
    }
    .instructions {
      .live {
        position: relative;
        padding-left: 10px;
        padding-right: 50px;
        &::after {
          position: absolute;
          right: 20px;
          bottom: 4px;
          content: '';
          width: 24px;
          height: 6px;
          background-color: #3c60fb;
        }
      }
    }
  }
  ::v-deep .ant-fullcalendar-calendar-body {
    .ant-fullcalendar-table {
      thead {
        tr[role='row'] {
          border-bottom: 1px solid #eee;
          .ant-fullcalendar-column-header-inner {
            padding: 5px 0 10px 0;
          }
        }
      }
      tbody {
        tr[role='row'] {
          border-bottom: 1px solid #eee;
        }
      }
    }
  }
  .cus-date-cell {
    color: #a1a3a2;
    text-align: center;
    padding-top: 5px;
    .date-value-wrap {
      position: relative;
      width: 28px;
      height: 28px;
      display: inline-block;
      line-height: 28px;
      cursor: pointer;
      .date-value {
        position: absolute;
        width: 100%;
        height: 100%;
        line-height: 26px;
      }
    }
    .current-month {
      color: #2d2f2f;
    }
    .today {
      &::before {
        position: absolute;
        left: 0;
        top: 0;
        content: '';
        width: 100%;
        height: 100% !important;
        background-color: #eff0f0 !important;
        border-radius: 50%;
        overflow: hidden;
      }
    }
    .current-day {
      &::before {
        position: absolute;
        left: 0;
        top: 0;
        content: '';
        width: 100%;
        height: 100% !important;
        border: 1px solid #3c60fb;
        background-color: transparent !important;
        border-radius: 50%;
        overflow: hidden;
      }
    }
    .has-live {
      &::before {
        position: absolute;
        left: 0;
        bottom: -4px;
        content: '';
        width: 100%;
        height: 6px;
        background-color: #3c60fb;
      }
    }
  }
}
.calendar-detail {
  padding-top: 96px;
  .live-info-item {
    margin-bottom: 10px;
    line-height: 24px;
    .live-info-item-title {
      position: relative;
      &::before {
        content: '';
        width: 8px;
        height: 8px;
        position: absolute;
        left: -20px;
        top: 10px;
        border-radius: 50%;
        overflow: hidden;
        background-color: #fb4f27;
      }
    }
  }
}
</style>
